/* src/global.css */
:root {
    --color-primary: #0968c6; /* Azul Bootstrap */
    --color-secondary: #6c757d; /* Gris Bootstrap */
    --color-success: #28a745; /* Verde Bootstrap */
    --color-danger: #dc3545; /* Rojo Bootstrap */
    --color-warning: #ffc107; /* Amarillo Bootstrap */
    --color-info: #17a2b8; /* Cian Bootstrap */
    --color-light: #dee3eb; /* Blanco grisáceo Bootstrap */
    --color-dark: #343a40; /* Negro grisáceo Bootstrap */
}

body {
    font-family: 'Arial', sans-serif;
    background-color: var(--color-light);
    color: var(--color-dark);
}


