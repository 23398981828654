.ant-table-thead > tr > th {
    background-color: #042956 !important;
    color: white !important;
    text-align: center !important; /* Centrando el texto */
    white-space: normal; /* Permite el ajuste de texto en múltiples líneas */
    word-wrap: break-word; /* Permite que las palabras largas se ajusten a la siguiente línea */
    line-height: 1.2; /* Ajusta la altura de la línea */
    padding: 10px; /* Espacio adicional para mejorar la legibilidad */
}

.ant-table-tbody > tr > td {
    white-space: nowrap; /* Evita el ajuste de texto en múltiples líneas */}

/* styles.css */
.ant-table-tbody > tr:hover {
    background-color: #086ac6 !important;
    cursor: pointer !important;
}


/* styles.css */
.ant-table-tbody > tr:hover {
    background-color: #086ac6;
    cursor: pointer;
}

.ant-table-tbody > tr:focus {
    background-color: #086ac6;
    outline: none; /* Elimina el borde de enfoque predeterminado */
}

.ant-table-tbody > tr.ant-table-row:hover .ant-table-cell {

    background-color: #b3d4f4 !important;
    cursor: pointer !important;
}

/* styles.css */
.ant-table-tbody > tr.ant-table-row:hover .ant-table-cell {
    background-color: #086ac6; /* Color de fondo para las celdas en hover */
    cursor: pointer;
    color: #042956 /* Color del texto en hover (opcional) */
}




span.anticon.anticon-filter {
    color: #FFF !important;
}

span.ant-table-column-sorter.ant-table-column-sorter-full {
    color: #FFF !important;
}

span.anticon.anticon-search {
    color: #FFF !important;
}

button.ant-btn.css-dev-only-do-not-override-m4timi.ant-btn-default.btn.btn-danger {
    padding-top: 1px !important;
    margin-top: -3px !important;
}

span.anticon.anticon-close.ant-modal-close-icon {
    background: #083a6d;
    border-radius: 5px;
    color: #FFF;
    font-size: 1.4rem;
    padding: 3px;
}

span.anticon.anticon-close.ant-modal-close-icon:hover{
    background: #0869c6 !important;
}

.ant-input-outlined[disabled] {
    color: #042956 !important;
    border-color: #0e0d0d !important;
    
}

.ant-select-disabled:where(.css-dev-only-do-not-override-m4timi).ant-select-multiple .ant-select-selection-overflow .ant-select-selection-item{
    color: #042956 !important;
}

.ant-table-expanded-row-fixed {
    background: #042956;
    color: #FFF;
}


.form-with-margin {
    margin: 20px; /* Aplica el margen deseado aquí */
  }
  
  .button-container {
    display: flex;
    justify-content: center; /* Centra los botones horizontalmente */
    gap: 10px; /* Espacio entre los botones */
    margin-top: 20px; /* Espacio superior del footer */
  }

  .participant-count-container {
    display: flex;
    align-items: center;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #f9f9f9;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.participant-count-title {
    font-size: 24px;
    font-weight: bold;
    color: #3E75A6 ;
    margin: 0;
}

.participant-count-number {
    font-size: 30px;
    font-weight: bold;
    color: #3E75A6 ;
}
