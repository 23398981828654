#map path {
    cursor: pointer; /* Aplica el estilo de cursor a todos los elementos <path> dentro del contenedor con ID 'map' */
}
.back-button {
    position: absolute;
    top: 3.7rem; /* Margen superior de 3rem */
    right: 10px; /* Mueve el botón hacia la derecha */
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    z-index: 1000;
}

.back-button:hover {
    background-color: #0056b3;
}

.header-container-depto {
    display: contents;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: fixed;
    top: 0;
    width: 100%;
    height: 60px; 
    
    color: #1959a3;
    font-weight: bold; /* Hace el texto más grueso */
    font-size: 1.2rem; /* Aumenta el tamaño del texto */
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3); /* Agrega una sombra sutil para mayor visibilidad */
    background-color: #f8f9fa; /* Agrega un fondo claro para que el texto resalte más */
    border-bottom: 2px solid #1959a3; /* Agrega una línea en la parte inferior para definir mejor la cabecera */

}
  

.cardCol-hover {
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
  }
  
  .cardCol-hover:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }

  .titleCol {
    color: #4375af !important;
}

.title-custom {
    font-size: 2.5rem; /* Tamaño del texto */
    font-weight: 700;  /* Grosor del texto */
    color: #388aea;    /* Color del texto */
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3); /* Sombra para un efecto 3D */
    margin-bottom: 20px; /* Espacio inferior */
    text-transform: uppercase; /* Texto en mayúsculas */
}