.contenido:hover {
    background: #042856;
    color: #FFF;
    transition: background-color 0.3s ease, box-shadow 0.3s ease; /* Agrega transiciones suaves */
    cursor: pointer;
}
.bg-info-archivos {
    
    background-color: #5f97cc !important;
    color: #FFF !important;
}

.btn-atras {
    align-self: flex-end; /* Alinea el botón al final del contenedor */
}

.miga-de-pan {
    display: flex;
    align-items: center;
}
.miga-de-pan p {
    margin-bottom: 0; /* Elimina el margen inferior de los párrafos dentro de la miga de pan */
}

.miga-link {
    cursor: pointer;
    color: #0c8be4;
    text-decoration: underline;
}

.miga-link:hover {
    color: darkblue;
}

.card-clickable {
    cursor: pointer;
    background-color: #ffffff;
}

.card-clickable:hover {
    background-color: #f0f0f0;
}


.disabled-card {
    background-color: #9e9e9e96 !important;
    color: #9E9E9E !important;
    cursor: not-allowed;
    pointer-events: none;
    position: relative;
    text-align: left;
}

.disabled-card::before {
    content: "\f057"; /* Unicode for FontAwesome icon */
    font-family: "FontAwesome";
    position: absolute;
    top: 50%; /* Center the icon vertically */
    
    transform: translate(-50%, -50%); /* Adjust for exact centering */
    opacity: 0.5; /* Optional: make the icon semi-transparent */
    font-size: 1.5rem; /* Adjust the size of the icon */
    color: #ff0000; /* Adjust the color of the icon */
    right: 50px;
}


.folder-name {
    cursor: pointer;
    font-weight: bold;
    margin-right: 5px;
}

.folder-contents {
    margin-left: 20px; /* Indentación para mostrar la estructura jerárquica */
}


h3.card-title.mb-0 {
    color: #042856 !important;
    font-weight: 800;
    font-size: 2rem;
}


p.card-text {
    color: #4175af;
}


.miga-pan{
    background: #e8e8e8;
    border-radius: 5px;
}

.contenedorresultados{
    border: 2px solid #0c8be4;
    padding: 2rem;
    border-radius: 10px;

}

.titulocontenedor{
    color: #23264e;
    font-size: 1rem;
    
}

.fa-2x{
    color: #4175af !important;
}

.nombredocumento{
    color:#17497b !important;
    line-height: 1rem;
    font-size: 1.2rem;

}
.modal.fade.show {
    background: #0707078c;
}

.nproyecto{
    color: #1462a4 !important;
}

.contenido:hover .cargarsoporte {
    color: #FFF !important;
}

.contenido:hover .text-primary {
    color: #FFF !important;
}

.ant-layout{
    background:none !important;
}

.ant-layout-sider{
    background: #dee3eb !important;
}

.ant-tree.ant-tree-icon-hide.css-dev-only-do-not-override-m4timi {
    border-radius: 10px !important;
}

.highlightedNode {
    background-color: yellow; /* Cambia el color de fondo a amarillo para resaltar */
    font-weight: bold; /* O cualquier otro estilo que desees aplicar */
}
.ant-upload-wrapper .ant-upload-drag{
    border: 1px dashed #0869c6 !important;
    margin-top: 14px !important;
}


button.ant-btn.css-dev-only-do-not-override-m4timi.ant-btn-primary.ant-btn-icon-only {
    background: #0869c6 !important;

}

.ant-tree-list-holder-inner {
    overflow: hidden;
}

span.anticon.anticon-folder-open {
    color: #FF9800;
}

span.anticon.anticon-folder {
    color: #FF9800;
}

span.anticon.anticon-file {
    color: #03A9F4;
}

.container-fluid{
    margin-right: 20px !important;
    margin-left: 20px !important;
}

.custom-modal-title {
    color: #0869c6; /* Cambia este color según tus necesidades */
    font-size: 24px; /* Cambia el tamaño de la fuente */
    font-weight: bold; /* Opcional: Hace el texto más grueso */
}

td.ant-descriptions-item-content {
    padding: 0 0 0 10px !important
}

th.ant-descriptions-item-label {
    padding: 0 0 0 10px !important;
}

.container-fluid{
    margin-left: 0px !important;
}

.ant-upload-list-item-name {
    color: #ff4d4f; /* Cambia el color al que desees */
  }

  .centered-dragger {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%; /* Ajusta la altura según sea necesario */
}

.ant-space.css-dev-only-do-not-override-1gwfwyx.ant-space-vertical.ant-space-gap-row-large.ant-space-gap-col-large {
    row-gap: 0px !important;
}

span.ant-tree-node-content-wrapper.ant-tree-node-content-wrapper-normal {
    display: flex !important;
}