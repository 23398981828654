.site-layout-background .ant-collapse-header .ant-collapse-arrow {
  color: white !important; /* Cambia el color del ícono (flecha) a blanco */
}

.site-layout-background .ant-collapse-header i {
  color: white !important; /* Cambia el color del ícono a blanco */
}

/* Estilos para la tabla específica */
.table-curso-fortalecimiento .custom-ant-table-wrapper .ant-table-cell,
.table-curso-fortalecimiento .custom-ant-table-wrapper .ant-table-thead>tr>th,
.table-curso-fortalecimiento .custom-ant-table-wrapper .ant-table-tbody>tr>th,
.table-curso-fortalecimiento .custom-ant-table-wrapper .ant-table-tbody>tr>td,
.table-curso-fortalecimiento .custom-ant-table-wrapper tfoot>tr>th,
.table-curso-fortalecimiento .custom-ant-table-wrapper tfoot>tr>td {
  position: relative !important;
  overflow-wrap: break-word !important;
}

.table-curso-fortalecimiento .custom-ant-table-wrapper thead {
  border-color: #ffffff !important;
  border-style: solid !important;
  border-width: 1px !important;
}
/* Eliminar el borde de separación en las celdas de la tabla */
.table-curso-fortalecimiento .ant-table-wrapper .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before,
.table-curso-fortalecimiento .ant-table-wrapper .ant-table-thead > tr > td:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  content: none !important;
  /* Eliminar los estilos de borde */
  background-color: transparent !important;
  width: 0 !important;
  height: 0 !important;
  transform: none !important;
}

:where(.css-dev-only-do-not-override-1gwfwyx).ant-table-wrapper .ant-table-cell, :where(.css-dev-only-do-not-override-1gwfwyx).ant-table-wrapper .ant-table-thead>tr>th{
  position: relative;
  padding: 4px 4px; 
  overflow-wrap: break-word;
  
}
thead.ant-table-thead {
border-color: #ffffff !important;
border-style: solid!important;;
}