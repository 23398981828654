html, body {
    height: 100%;
    margin: 0;
}

#root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

main {
    flex: 1; 
}

.logos-footer {
    padding: 20px;
    background-color: #328bc7;
    text-align: center;
    width: 100%;
    z-index: 1000;
    margin-top: auto;
}

.logo-image {
    width: 100%;
    max-width: 200px;
    height: auto;
    cursor: pointer;
    transition: transform 0.3s ease;
}

.logo-image:hover {
    transform: scale(1.1);
}

a {
    text-decoration: none;
}
