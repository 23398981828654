/* Estilos generales y variables */
:root {
  --color-primary: #0968c6; /* Azul Bootstrap */
  --color-secondary: #6c757d; /* Gris Bootstrap */
  --color-success: #28a745; /* Verde Bootstrap */
  --color-danger: #dc3545; /* Rojo Bootstrap */
  --color-warning: #ffc107; /* Amarillo Bootstrap */
  --color-info: #408cc8; /* Cian Bootstrap */
  --color-light: #dee3eb; /* Blanco grisáceo Bootstrap */
  --color-dark: #343a40; /* Negro grisáceo Bootstrap */
}

#root {
  background: var(--color-light);
}

/* Estilos para input y botones */
.input-group {
  background-color: #0967c600;
}

.input-group .form-control {
  border: 1px solid var(--color-secondary);
}

.input-group.shadow-sm {
  padding-bottom: 7px;
}

.input-group .btn {
  color: #fff;
}

.container.elemtos {
  padding: 22px;
}

button.btn.btn-primary {
  background-color: var(--color-primary);
}

.results {
  border-top: 1px solid var(--color-secondary);
  padding-top: 1rem;
}

.btn-primary {
  transition: transform 0.3s ease;
}

.btn-primary:hover {
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  transform: scale(1.1) !important;
  font-size: 1rem;
  color: #FFF !important;
  background: #042956 !important;
}

.btn-primary-lis:hover {
  transform: scale(1.3) !important;
  font-size: 1.3rem;
  color: #042956 !important;
}

/* Estilos para tarjetas (cards) del componente específico */
.proyecto-card-container {
  padding: 20px;
}

.proyecto-card {
  border-radius: 1.2rem !important;
  overflow: hidden !important;
  background-color: #fff;
}

.proyecto-card-header {
  padding: 9px;
  background: linear-gradient(135deg, #317eda, #002b6f); /* Degradado de azul a azul más oscuro */
  color: #FFF;
  transition: background 0.3s ease, color 0.3s ease;
}

.proyecto-card:hover .proyecto-card-header {
  background: linear-gradient(135deg, #002b6f, #001a44); /* Degradado más oscuro */
  color: #FFF;
}

.proyecto-card-body {
  background-color: #fff;
  color: #042956; /* Color de texto para el cuerpo de la tarjeta */
}

.proyecto-card-header .proyecto-card-title {
  color: inherit; /* Inherit color from parent */
}

.proyecto-cumpliminetoporcentaje {
  line-height: 0.9rem;
  margin-top: 10px;
}

.redondear {
  border-radius: 1.2rem !important;
  overflow: hidden !important;
}

.proyecto-card-body .distribucion {
  padding: 0px !important;
  text-align: center;
  margin-top: 10px;
}

.actualizacion {
  font-size: 13px !important;
}

/* Estilos adicionales para otros componentes */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 1);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icototales {
  color: #408cc8;
  font-size: 1rem;
}

.icototalesvalor {
  color: #042956;
  font-size: 1.5rem;
}

svg.svg-inline--fa.fa-table-cells,
svg.svg-inline--fa.fa-list {
  padding: 5px;
}

button.ant-btn.css-dev-only-do-not-override-m4timi.ant-btn-default.ant-dropdown-trigger.btn.btn-primary.m-0.d-flex.align-items-center.justify-content-center {
  padding: 19px;
}

.ant-card-body {
  padding: 0px !important;
}

.proyecto-card-body.distribucion {
  margin: 30px;
  padding: 16px !important;
  background-color: #fff;
}

.card-footer {
  background-color: #fff !important;
  border: none !important;
}

.card-footer:hover {
  background-color: #f8f9fa;
}

.card-header.card-white {
  background-color: #fff;
  color: #042956;
}

.card-header.card-orange {
  background-color: #f68f41;
  color: #fff;
}

.card-header.card-yellow {
  background-color: #eab403d1;
  color: #042956;
}

.card-header.card-green {
  background-color: #3BB44A;
  color: #fff;
}

.card:hover .card-header.card-white {
  background-color: #fff;
  color: #042956;
}

.card:hover .card-header.card-orange {
  background-color: #d07f41;
  color: #fff;
}

.card:hover .card-header.card-yellow {
  background-color: #e5ac1a;
  color: #042956;
}

.card:hover .card-header.card-green {
  background-color: #467337;
  color: #fff;
}

.card-header.card-white .card-text,
.card-header.card-orange .card-text,
.card-header.card-yellow .card-text,
.card-header.card-green .card-text {
  color: inherit;
}

.header-filter-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.input-group {
  margin-left: auto;
}

.form-control {
  border-radius: 0.25rem;
}

.btn-primary {
  border-radius: 0.25rem;
}

.select-container .ant-select-selector {
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  padding-top: 8px;
}

.select-container .ant-tag,
.select-container .ant-select-selection-item {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  margin-bottom: 4px;
}

