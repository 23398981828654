span.ant-tree-title {
    display: inline-grid !important;
}

.ant-upload.ant-upload-select {
    width: 0px !important; 
}

.icon-button {
    background: none;
    border: none;
    color: inherit;
    padding: 0;
    font: inherit;
    cursor: pointer;
    margin: 0 4px; /* Ajusta el margen según sea necesario */
  }
  
  .icon-container {
    display: inline-flex;
    align-items: center;
  }

  span.ant-tree-iconEle.ant-tree-icon__customize {
    padding-top: 3px !important; 
  } 

  
  
/* styles.css o el archivo CSS de tu componente */
.custom-tree .ant-tree-node-selected .ant-tree-title .btn-primary-lis {
  color: #ffffff !important; /* Cambia el color al rojo o al que prefieras */
}

