.form-with-margin {
  margin: 20px; /* Aplica el margen deseado aquí */
}

.button-container {
  display: flex;
  justify-content: center; /* Centra los botones horizontalmente */
  gap: 10px; /* Espacio entre los botones */
  margin-top: 20px; /* Espacio superior del footer */
}

/* Estilo para el título principal */
.main-title {
  margin-top: 10px;
  flex-grow: 1 !important;
  color: #3E75A6 !important ;
  font-weight: bold !important;
  text-align: center !important;
  font-size: 20px !important;
}
