.gradient-text {
    text-align: justify;
    text-justify: inter-word;
    background: linear-gradient(45deg, rgb(139, 191, 68), rgb(105, 155, 51)); /* Degradado de verde más oscuro */
    -webkit-background-clip: text; /* Para navegadores basados en WebKit (Chrome, Safari) */
    background-clip: text; /* Propiedad estándar para compatibilidad general */
    -webkit-text-fill-color: transparent; /* Hace que el color del texto sea transparente */
    font-weight: bold; /* Opcional: Aumenta el grosor del texto para mejorar la visibilidad del degradado */
}

.justified-title {
    text-align: justify;
    text-justify: inter-word;
}

.custom-card {
    border-radius: 8px; /* Borde redondeado */
    background: #f9f9f9; /* Fondo de la tarjeta */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Sombra suave para la tarjeta */
    transition: transform 0.3s, box-shadow 0.3s; /* Transición suave para el efecto hover */
    display: flex; /* Utiliza flexbox para centrar el contenido */
    flex-direction: column; /* Alinea el contenido verticalmente */
    height: 100%; /* Asegura que la tarjeta ocupe toda la altura del contenedor */
    width: 100%; /* Asegura que la tarjeta ocupe toda la anchura del contenedor */
    max-width: 300px; /* Establece un ancho máximo para la tarjeta */
    margin-bottom: 20px; /* Margen en la parte inferior de la tarjeta */
    padding: 20px; /* Padding interno para dar espacio al contenido dentro de la tarjeta */
}

.custom-card:hover {
    background-color: #f0f4f8; /* Color de fondo cuando se pasa el cursor sobre la tarjeta */
    transform: scale(1.02); /* Escala suave para el efecto hover */
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2); /* Sombra más intensa para el efecto hover */
}

.card-title {
    background: linear-gradient(90deg, #338BC7, #1e3a60); /* Gradiente para el encabezado */
    -webkit-background-clip: text; /* Aplica el gradiente al texto */
    background-clip: text; /* Propiedad estándar para compatibilidad general */
    
    font-weight: bold; /* Negrita para el encabezado */
    text-align: center; /* Centra el texto del encabezado */
}

.card-content {
    display: flex; /* Utiliza flexbox para centrar el contenido */
    flex-direction: column; /* Alinea el contenido verticalmente */
    justify-content: center; /* Centra el contenido verticalmente */
    align-items: center; /* Centra el contenido horizontalmente */
    height: 100%; /* Asegura que el contenido ocupe toda la altura de la tarjeta */
}


.custom-tree-estructuracion {
    max-width: 300px; /* Ancho máximo de 300 píxeles */

}
.custom-card {
    height: 250px; /* Altura fija para todas las tarjetas */
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Alinear contenido */
    margin: 10px; /* Espacio entre tarjetas */
}

.custom-panel-estructuracion .ant-collapse-header {
    color: white !important;
    background: #287ab1;
}
.custom-background {
    background-color: red; /* Color de fondo */
    width: 100%;           /* Asegúrate de que ocupe todo el ancho */
    padding: 0px;        /* Añade algo de padding si lo deseas */
    box-sizing: border-box; /* Asegúrate de que el padding no afecte el tamaño total */
}
.collate-background-estructuracion{
    background: #f0f4f8;
}
.collate-background-estructuracion .ant-collapse-content-active {
    background-color: "#dee3eb" !important; /* Cambia el fondo a rojo */
}
.custom-card-header .ant-card-head {
    background-color: #338bc7; /* Cambia el color de fondo */
    color: white; /* Cambia el color del texto */
}
.custom-card .ant-card-body {
    padding: 16px !important; /* Ajusta el valor del padding como necesites */
}



.custom-card-container {
    /* Puedes agregar estilos aquí para el contenedor */
}

.custom-card {
    display: flex;
    flex-direction: column;
    height: auto; /* Asegúrate de que la altura se ajuste automáticamente */
}

.custom-card-header {
    flex-grow: 0; /* Si tienes un header, no debe crecer */
}

.custom-card-container .ant-card-body {
    color: #171a1c; /* Cambia el color del texto */
    background: #e9ecef; /* Cambia el color de fondo */
    padding: 16px; /* Ajusta el padding interno según necesites */
}
