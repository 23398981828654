/* src/global.css */
:root {
    --color-primary: #0968c6; /* Azul Bootstrap */
    --color-secondary: #6c757d; /* Gris Bootstrap */
    --color-success: #28a745; /* Verde Bootstrap */
    --color-danger: #dc3545; /* Rojo Bootstrap */
    --color-warning: #ffc107; /* Amarillo Bootstrap */
    --color-info: #408cc8; /* Cian Bootstrap */
    --color-light: #dee3eb; /* Blanco grisáceo Bootstrap */
    --color-dark: #343a40; /* Negro grisáceo Bootstrap */
}
#root
{
    background: #dee3eb;
}
.input-group {
    background-color: #0967c600;
}

.input-group .form-control {
    border: 1px solid var(--color-secondary);
}

.input-group .btn {
    
    color: #fff;
}
button.btn.btn-primary
{
    background-color: #0968c6
}

.results {
    border-top: 1px solid var(--color-secondary);
    padding-top: 1rem;
}
.card-footer{
    
    background-color:#fff !important;
    border: none !important;

}
.card-footer:hover{
    background-color: #f8f9fa; /* Cambia el color de fondo al hacer hover */
}

.card:hover {
    background-color: #f8f9fa; /* Cambia el color de fondo al hacer hover */
    box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 49%); /* Agrega una sombra al hacer hover */
    transition: background-color 0.3s ease, box-shadow 0.3s ease; /* Agrega transiciones suaves */
    cursor: pointer;
}

.btn-primary{
    transition: transform 0.3s ease;

}

.btn-primary:hover{
   transition: background-color 0.3s ease, box-shadow 0.3s ease; /* Agrega transiciones suaves */
   transform: scale(1.1) !important;
    font-size: 1rem;
    color: #FFF !important;
    background: #042956!important;
}

.btn-primary-lis:hover{
    transform: scale(1.3) !important;
    font-size: 1.3rem;
    color: #042956 !important;

}

.cumplimiento{
    font-weight: 800;
    color: #042956;
    font-size: 1.3rem; 
}
.cumpliminetoporcentaje{
    line-height: 0.9rem;
    margin-top: 10px;

}

.separator {
    margin: 0 5px; /* Espacio entre el icono y el separador */
}

.card .card-header.sin-soportes {
    background: #ff0e06;
    color: #FFF;
    transition: background 0.3s ease, color 0.3s ease; /* Agrega transiciones suaves */
}

.card .card-header.validado {
    background: #ff0e06;
    color: #FFF;
    transition: background 0.3s ease, color 0.3s ease; /* Agrega transiciones suaves */
}

.card .card-header.pendiente {
    background: #ff5722;
    color: #FFF;
    transition: background 0.3s ease, color 0.3s ease; /* Agrega transiciones suaves */
}

.card:hover .card-header {
    color: #FFF;
    transition: background 0.3s ease, color 0.3s ease; /* Agrega transiciones suaves */
}


.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 1);
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
}

.actualizacion{
    font-size: 13px !important;
}

.distribucion{
    padding: 0px !important;
    text-align: center;
    margin-top: 10px;
}

.btn-primary-lis {
    color: #2196F3 !important;
}
.objeto{
    color: #042956;
    font-size: 1rem;
    text-align: justify;
    line-height: 1.3rem;
    padding: 10px;
}

.titulo_proyecto{
    font-size: 1.4rem;
    color: #042956;
    font-weight: 900 !important;
    text-align: center;
    margin-right: 5px;
    line-height: 1.2rem;
    border-right: 2px solid #042956;
    margin-bottom: 10px;        
}

.linea_separador{
    margin-top: 5px;
    border-bottom: 4px solid #0869c6;   
}

.codproyecto{
    font-size:14px !important;
    color: #fff !important;
}

.redondear{
    border-radius: 1.2rem !important;
    overflow: hidden !important;
}

.card-body:hover{
    background-color:#FFF;
}
.cumplimientodetalle{
    color:#5fa5d0;
}

.detalle_proyecto {
    background-color: var(--color-light);
}

.icototales{
    color: #408cc8;
    font-size: 1rem;
}

.icototalesvalor {
    color: #042956;
    font-size: 1.5rem;
}

svg.svg-inline--fa.fa-table-cells {
    padding: 5px;
}

svg.svg-inline--fa.fa-list {
    padding: 5px;
}

button.ant-btn.css-dev-only-do-not-override-m4timi.ant-btn-default.ant-dropdown-trigger.btn.btn-primary.m-0.d-flex.align-items-center.justify-content-center {
    padding: 19px;
}


.traffic-light {
    display: flex;
    align-items: center;
  }
  
  .circle {
    width: 15px; /* Ajusta el tamaño del círculo */
    height: 15px; /* Ajusta el tamaño del círculo */
    border-radius: 50%;
    margin-right: 10px; /* Espaciado entre el círculo y el texto */
  }
  