
.navbar {
  margin-bottom: 20px !important;
  padding-left: 2rem !important;
  background-color: #338bc7 !important;
}

a.nav-link {
  color: #FFF;
}

div#basic-navbar-nav {
  background-color: #338bc7 !important;
}

.nav-link:not(:last-child) {
border-right: 1px solid #65abd9; 
padding-right: 15px; 
}

.nav-link:last-child {
border-right: none;
}

.nav-content {
display: flex;
justify-content: space-between;
align-items: center;
width: 100%;
}

.nav-items {
display: flex;
align-items: center;
}

.nav-profile {
display: flex;
align-items: center;
margin-left: auto; 
}

.navbar-container .nav-link {
white-space: nowrap;
display: flex; 
align-items: center; 
}

.navbar-container .nav-link svg {
margin-right: 8px; 
}


.navbar-container .nav-item {
flex: 1; 
}




/* Estilo para alinear los submenús a la derecha */
.nested-dropdown .dropdown-menu {
left: 100%;
top: 0;
margin-left: 0.1rem;
}

.nav-dropdown .dropdown-menu-end {
right: 0;
left: auto;
}

#nested-dropdown-Implementación {
color: #338bc7;
}