/* mapaInicio.css */
html, body, #root {
    height: 100%;
    margin: 0;
    padding: 0;
  }
  
  .map-container {
    position: relative;
    width: 100%;
    height: 100%;
  }
  
  .map-iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
  }
  