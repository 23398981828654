/* Asegúrate de que las clases sean únicas usando un prefijo como proyecto-cards- */
.proyecto-cards-container {
    padding: 20px;
  }
  
  .proyecto-cards-row {
    margin-bottom: 20px;
  }
  
  .proyecto-cards-col-md-8 {
    display: flex;
    justify-content: center;
  }
  
  .proyecto-cards-titulo p {
    font-weight: bold;
    text-align: center;
    margin: 0;
  }
  
  .proyecto-cards-objeto p {
    font-size: 16px;
    color: #666;
  }
  
  .proyecto-card {
    background-color: white;
    transition: box-shadow 0.3s ease-in-out;
  }
  
  .proyecto-card .ant-card-head {
    background-color: #28a745; /* Fondo verde para el título */
    padding: 10px;
  }
  
  .proyecto-card .ant-card-head-title {
    text-align: center; /* Titulo centrado */
    font-weight: bold; /* Titulo en negrita */
  }
  
  .proyecto-card .ant-card-body {
    padding: 20px;
  }
  
  .proyecto-card .ant-card-footer {
    padding: 10px;
    display: flex;
    justify-content: flex-end;
  }
  
  .proyecto-card:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Efecto de sombra cuando pasa el mouse */
  }

  
  .card-header:hover {
    background-color: #284e7c; /* Cambia el fondo solo del header */
    cursor: pointer; /* Cambia el cursor */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); /* Agrega una sombra */
}