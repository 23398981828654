.card-graficos {
  position: relative;  
  width: 250px; 
  height: 120px; 
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* Estilos específicos para el card-body dentro de las cards */
#card-proyectos .card-body,
#card-organizaciones .card-body,
#card-hombres .card-body,
#card-mujeres .card-body,
#card-documentos .card-body {
  background-color: #104378;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  color: #ddd;
  height: 100%; /* Asegura que el card-body ocupe toda la altura de la card */
  
}

#title-proyectos,
#title-organizaciones,
#title-hombres,
#title-mujeres,
#title-documentos {
  font-size: 18px;
  font-weight: bold;
  margin: 0;
  text-align: center;
}

#value-proyectos,
#value-organizaciones,
#value-hombres,
#value-mujeres,
#value-documentos {
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  margin-top: 1rem;
}

#card-proyectos:hover .card-body,
#card-organizaciones:hover .card-body,
#card-hombres:hover .card-body,
#card-mujeres:hover .card-body,
#card-documentos:hover .card-body {
  background-color: #104378 !important;
  color: #ddd !important;
}

.card-details {
  display: none; /* Oculto por defecto */
  position: relative; /* Se posiciona en relación con el card */
  background-color: #fff; /* Fondo blanco para el contenido */
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  margin-top: 0.5rem; /* Ajusta la posición para que aparezca justo debajo del card */
  width: 100%; /* Asegura que ocupe todo el ancho del card */
  z-index: 10; /* Asegura que el contenido esté encima de otros elementos */
}

#card-documentos:hover .card-details {
  display: block; /* Muestra el contenido cuando el ratón está sobre el card */
}

.detail-item {
  margin-bottom: 1rem; /* Espaciado entre los elementos */
}

/* Estilos para la tabla */
.details-table-container {
  max-height: 200px; /* Ajusta este valor según la altura deseada */
  overflow-y: auto; /* Habilita la barra de desplazamiento vertical */
}

.details-table {
  width: 100%;
  border-collapse: collapse;
}

.details-table th,
.details-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.details-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

/* Estilo para la flecha */
.down-arrow {
  font-size: 24px;
  margin-left: 10px;
  color: #ddd;
}


  .containerGraficos {
    display: flex;
    gap: 20px; /* Ajusta este valor según el espaciado deseado */
    margin: 20px;
}
  
  .graficoBarras1 {
    width: 100%;
    overflow-x: auto; /* Habilita la barra de desplazamiento horizontal */

  }
  
  .chartWrapper {
    width: 1500px; /* Ajusta este valor según sea necesario para evitar la superposición de nombres */
  }
  
  .graficoBarras2 {
    width: 100%;
    overflow-x: auto; /* Habilita la barra de desplazamiento horizontal */
  }
  
  .graficoBarrasHorizontal{
     width: 100%;
     overflow-y: auto;
  }
  

  /* Asegúrate de que esta clase esté en el archivo CSS que se importa en el componente del gráfico */
.my-custom-tooltip {
  background-color: #fff; /* Fondo oscuro */
  color: #477337; /* Texto blanco */
  padding: 10px; /* Espaciado interno */
  border-radius: 5px; /* Bordes redondeados */
  font-size: 14px; /* Tamaño de fuente */
  line-height: 1.5; /* Altura de línea */
  box-shadow: #477337; /* Sombra de caja */
}

.my-custom-tooltip .label {
  font-weight: bold; /* Texto en negrita */
}

.my-custom-tooltip .intro {
  margin-top: 5px; /* Espaciado superior */
  font-size: 12px; /* Tamaño de fuente más pequeño para detalles */
}
