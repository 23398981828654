/* src/components/LoginBodecom/LoginBodecom.css */
.login-background {
  background: url('../../assets/bg_fao_bodecom.png') no-repeat center top fixed;
  background-size: cover;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

  /* Asegúrate de ajustar estas rutas según la estructura de tu proyecto */

  
  .login-form-wrapper {
    display: flex;
    justify-content: center;
    align-items: center; /* Centra verticalmente */
    height: auto; /* Ajusta la altura según el contenido */
    width: 350px;
    max-width: 400px; /* Ancho máximo del formulario */
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 40px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    background-color: rgba(255, 255, 255, 0.678);
    border: 4px solid #224484;
  }
  
  .login-form h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .login-form .ant-form-item {
    margin-bottom: 15px;
  }
  
  .login-form .ant-btn {
    width: 100%;
  }
  
  .no-decoration-link {
    text-decoration: none;
  }

 .titleLabel {
    color: #224484;
    font-weight: bold;
}

.logo:hover {
    transform: scale(1.1); 
    cursor: pointer;
  }

  @media (max-width: 768px) {
    .login-form-wrapper {
      width: 90%;
      padding: 15px;
      border-radius: 15px;
    }
  
    .logo {
      max-width: 200px; /* Reduce el tamaño del logo en pantallas más pequeñas */
    }
  }
  
  @media (max-width: 576px) {
    .login-form-wrapper {
      width: 95%;
      padding: 10px;
      border-radius: 10px;
    }
  
    .logo {
      max-width: 150px; /* Reduce aún más el tamaño del logo en pantallas muy pequeñas */
    }
  }