.ant-rate-star {
    color: #ffffff; /* Cambia el color de las estrellas no seleccionadas */
    border: 1px solid #a9a7a7; /* Borde de las estrellas */
    border-radius: 10%; /* Borde redondeado */
  }
  
  .ant-rate-star:hover {
    color: #007BFF; /* Cambia el color al pasar el ratón */
    border-color: #007BFF; /* Cambia el borde al pasar el ratón */
  }
  