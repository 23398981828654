.bg-primary {
    background-color: #8dc145 !important;
}

.metageneral {
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    padding: 10px;
    border-radius: 4px;
    font-size: 1.5rem;
}

.metageneral:hover {
    background-color: #f0f0f0;
    color: #333;
    cursor: pointer;
    transform: scale(1.1);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.card-transition {
    transition: opacity 0.3s ease, max-height 0.5s ease;
    opacity: 1;
    max-height: 200px;
}

.card-hidden {
    opacity: 0;
    max-height: 0;
    overflow: hidden;
}

.custom-panel .ant-collapse-header:hover {
    background-color: #338bc7;
    color: #fff;
}

.truncated-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ant-table-thead>tr>th {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.container-fluid {
    margin-right: 20px !important;
    margin-left: 0px !important;
}

li.ant-timeline-item.ant-timeline-item-left {
    padding-bottom: 2px !important;
}

.custom-button-pe:hover {
    background: #4caf50 !important;
    transform: scale(1.1);
}

td.ant-table-cell {
    padding: 5px 10px !important;
}

.resultadosActividades {
    padding: 0px !important;
    margin-top: 10px !important;
    border: solid #9E9E9E !important;
    border-radius: 10px !important;
}

.ant-collapse-item {
    border-bottom: 1px solid #a19e9e !important;
}