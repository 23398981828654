/* Importar fuente de Google */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

/* Estilo para el contenedor del formulario */
.form-container {
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
  font-family: 'Roboto', sans-serif;
}

/* Estilo para la tarjeta del formulario */
.form-card {
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}
.ant-steps {
   margin: 10px;
  }

/* Estilo para los pasos */
.ant-steps-item-title {
  font-size: 12px;
}

@media (max-width: 768px) {
  .ant-steps {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row !important;
  }
  
  .ant-steps-item {
    flex: 0 0 33.33%;
    max-width: 33.33%;
  }

  .ant-steps-item-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .ant-steps-item-icon {
    margin-bottom: 8px;
  }

  .ant-steps-item-title {
    text-align: center;
  }
  
}

/* Estilo para el título principal */
.main-title {
    text-align: center; /* Centra el título principal */
    font-size: 32px; /* Ajusta el tamaño de la fuente según tus necesidades */
    margin-bottom: 20px; /* Espacio debajo del título principal */
    color: #333; /* Color del texto */
  }
  
  /* Estilo para el título del paso */
  .step-title {
    position: relative;
    display: inline-block;
    font-size: 24px; /* Ajusta el tamaño de la fuente según tus necesidades */
    margin-bottom: 20px; /* Espacio debajo del título */
    color: #333; /* Color del texto */
    font-weight: 600; /* Peso de la fuente */
    text-align: center; /* Centra el texto del título */
    width: 100%; /* Asegura que el título esté centrado en el contenedor */
  }
  
  /* Línea debajo del título */
  .step-title::after {
    content: "";
    display: block;
    width: 60%; /* Ajusta el ancho de la línea según tus necesidades */
    height: 4px; /* Altura de la línea */
    background-color: #328BC7; /* Color de la línea #328BC7 */
    position: absolute;
    bottom: -10px; /* Ajusta la posición vertical de la línea */
    left: 50%; /* Centra la línea horizontalmente */
    transform: translateX(-50%); /* Ajusta la línea al centro del título */
    border-radius: 2px; /* Opcional: agrega bordes redondeados a la línea */
  }
/* Estilo para el contenido de los pasos */
.steps-content {
  margin-top: 20px;
  transition: opacity 0.3s ease-in-out;
}

/* Estilo para los botones de navegación */
.steps-action {
  margin-top: 20px;
}

.form-with-margin {
  margin: 20px; /* Aplica el margen deseado aquí */
}

.button-container {
  display: flex;
  justify-content: center; /* Centra los botones horizontalmente */
  gap: 10px; /* Espacio entre los botones */
  margin-top: 20px; /* Espacio superior del footer */
}


.ant-table-thead > tr > th {
  background-color: #042956 !important;
  color: white !important;
  text-align: center !important; /* Centrando el texto */
  white-space: normal; /* Permite el ajuste de texto en múltiples líneas */
  word-wrap: break-word; /* Permite que las palabras largas se ajusten a la siguiente línea */
  line-height: 1.2; /* Ajusta la altura de la línea */
  padding: 10px; /* Espacio adicional para mejorar la legibilidad */
}

.ant-table-tbody > tr > td {
  white-space: nowrap; /* Evita el ajuste de texto en múltiples líneas */}

/* styles.css */
.ant-table-tbody > tr:hover {
  background-color: #086ac6 !important;
  cursor: pointer !important;
}


/* styles.css */
.ant-table-tbody > tr:hover {
  background-color: #086ac6;
  cursor: pointer;
}

.ant-table-tbody > tr:focus {
  background-color: #086ac6;
  outline: none; /* Elimina el borde de enfoque predeterminado */
}

.ant-table-tbody > tr.ant-table-row:hover .ant-table-cell {

  background-color: #b3d4f4 !important;
  cursor: pointer !important;
}

/* styles.css */
.ant-table-tbody > tr.ant-table-row:hover .ant-table-cell {
  background-color: #086ac6; /* Color de fondo para las celdas en hover */
  cursor: pointer;
  color: #042956 /* Color del texto en hover (opcional) */
}




span.anticon.anticon-filter {
  color: #FFF !important;
}

span.ant-table-column-sorter.ant-table-column-sorter-full {
  color: #FFF !important;
}

span.anticon.anticon-search {
  color: #FFF !important;
}

button.ant-btn.css-dev-only-do-not-override-m4timi.ant-btn-default.btn.btn-danger {
  padding-top: 1px !important;
  margin-top: -3px !important;
}

span.anticon.anticon-close.ant-modal-close-icon {
  background: #083a6d;
  border-radius: 5px;
  color: #FFF;
  font-size: 1.4rem;
  padding: 3px;
}

span.anticon.anticon-close.ant-modal-close-icon:hover{
  background: #0869c6 !important;
}

.ant-input-outlined[disabled] {
  color: #042956 !important;
  border-color: #0e0d0d !important;
  
}

.ant-select-disabled:where(.css-dev-only-do-not-override-m4timi).ant-select-multiple .ant-select-selection-overflow .ant-select-selection-item{
  color: #042956 !important;
}

.ant-table-expanded-row-fixed {
  background: #042956;
  color: #FFF;
}


