.tabla-container tbody.ant-table-tbody {
    background: #ffffff; 
}


.tabla-container {
    overflow-x: auto; 
}

.table-curso-fortalecimiento {
    width: 100%; 
    min-width: 600px; 
}


@media (max-width: 768px) {
    .table-curso-fortalecimiento {
        font-size: 12px; 
    }
    
    .table-curso-fortalecimiento .column-genero,
    .table-curso-fortalecimiento .column-etnia,
    .table-curso-fortalecimiento .column-edad {
        display: none;
    }
}


.filtro-container {
    display: flex;
    flex-wrap: wrap;  
    gap: 10px; 
    margin-bottom: 16px;
}

.filtro-item {
    flex: 1 1 180px; 
    min-width: 160px;  
    max-width: 196px;  
}

.col-limpiar-filtros .anticon-search {
    color: #000000 !important;
}